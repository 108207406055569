/* Slider */
.slick-slider
{
    position: relative;
    height: 100vh !important;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slider-wrapper {
    width: 100%;
    height: 90vh;
  }
  .slide__container {
    height: 90vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .slide-content {
    position: relative;
    z-index: 1;
  }
  
  .bg-overlay {
    background-color: rgba(54,75,110, 0.5); /* Optional: to add an overlay over the image for better text visibility */
    padding: 20px;
    border-radius: 5px;
  }
  
  /* Optional additional styling */
  .slide-content h1,
  .slide-content h2 {
    color: white;
  }

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 5;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slide-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow : hidden;
}

.poph5
{
    z-index: 100;
    opacity: 0%;
    position: relative;
    display: inline-block;
    animation: popup 1s linear 1.5s forwards;
}
.popBtnL
{
    z-index: 100;
    opacity: 0%;
    position: relative;
    display: inline-block;
    animation: popl 1s linear 1.5s forwards;
}
.popBtnR
{
    z-index: 100;
    opacity: 0%;
    position: relative;
    display: inline-block;
    animation: popr 1s linear 1.5s forwards;
}
.poph2
{
    opacity: 0%;
    z-index: 100;
    position: relative;
    animation: popup 1s linear 2.5s forwards ;
}
.popp
{
    z-index: 100;
    opacity: 0%;
    position: relative;
    display: inline-block;
    animation: popup 1s linear 4s forwards;
}
.popbtn
{
    z-index: 100;
    opacity: 0%;
    position: relative;
    display: inline-block;
    animation: popup 1s linear 4.5s forwards;
}


@keyframes popup {
    from {
        transform: translateY(-200%);
        opacity: 0%;
    }


    to{
        transform: translateY(0%);
        opacity: 100%;  }
}
@keyframes popl {
    from {
        transform: translateX(-200%);
        opacity: 0%;
    }


    to{
        transform: translateX(0%);
        opacity: 100%;  }
}
@keyframes popr {
    from {
        transform: translateX(200%);
        opacity: 0%;
    }


    to{
        transform: translateX(0%);
        opacity: 100%;  }
}