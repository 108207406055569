/* Dashboard.css */

/* General styles for the sidebar */
.sidebar {
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}



.offcanvas {
    max-width: 60% !important;
    background-color: #0e2f50;
  }
  #menu {
    padding: 0 !important;
  }
  
  .nav-link {
    padding: 0 !important;

  }
  .offcanvas-backdrop.show{
    background-color: transparent;
  }

  .bgh {
    min-height: 80vh;
  }


*, *::before, *::after {
    box-sizing: border-box;
}
canvas.chartjs-render-monitor {
  aspect-ratio: 440 / 190;
}

.nk-wgacc-ck {
  display: block;
  width: 220px;
  height: 95px;
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 1ms;
}

canvas {
  width: 100% !important;
  max-width: 100%;
  height: 100% !important;
}


.chartjs-render-monitor {
    animation: chartjs-render-animation 1ms;
    animation-duration: 1ms;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: chartjs-render-animation;
    animation-timeline: auto;
    animation-range-start: normal;
    animation-range-end: normal;
}
