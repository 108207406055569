.my-nav {
  height: 100%;
  background-color: #f5f5f5;
  color: #0a1d47;
}

.my-nav .nav-item {
  color: #0a1d47;
  transition: all 0.5s ease-in-out;
}

.my-nav .nav-item .dropdown-item {
  padding: 5px 10px;
}

.my-nav .nav-item:hover {
  border-bottom: 3px solid #0a1d47;
}

.my-nav .nav-link {
  color: #ffffff;
}

#collapsible-nav-dropdown {
  color: #0a1d47;
  padding: 1px;
}

.active {
  border-bottom: 3px solid #0a1d47;
}

.navbar-toggler {
  padding-top: 8px;
  width: 28px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background: #0a1d47;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.mid-bar {
  margin-top: 0px;
}

/* when navbar is clicked*/
.navbar-toggler .top-bar {
  margin-top: 0;
  transform: rotate(135deg);
}

.navbar-toggler .mid-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0;
  transform: rotate(-135deg);
}

/* state when navbar iscollapsed*/
.navbar-toggler.collapsed .top-bar {
  margin-top: -10px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .mid-bar {
  opacity: 1;
  width: 24px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 10px;
  width: 20px;
  transform: rotate(0deg);
}

.top__header {
  background-color: #001d38;
  padding: 12px 0;
}

.logo {
  height: 6rem;
  width: 8rem;
  -o-object-fit: contain;
  object-fit: contain;
} 
.profile__logo {
  -o-object-fit: contain;
     object-fit: contain;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(208, 201, 201);
}

.person__logo {
  cursor: pointer !important;
  color: white !important;
}

.profile {
  position: relative;
}

.profile__actions {
  position: absolute;
  top: 130%;
  left: 0;
  width: 150px;
  z-index: 101;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 4px;
  display: none;
  cursor: pointer;
}

.profile__actions div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.show__profileAction {
  display: block;
}/*# sourceMappingURL=Header.css.map */