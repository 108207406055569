/*** Section Title ***/
.section-title::before {
    position: absolute;
    content: "";
    width: 120px;
    height: 4px;
    left: 0;
    bottom: 0;
    background: #f9bd0d;
    border-radius: 2px;
}

.section-title.text-center::before {
    left: 50%;
    margin-left: -75px;
}

.section-title.section-title-sm::before {
    width: 90px;
    height: 3px;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 4px;
    bottom: 0px;
    background: #ffffff;
    -webkit-animation: section-title-run 5s infinite linear;
    animation: section-title-run 5s infinite linear;
}

.section-title.section-title-sm::after {
    width: 4px;
    height: 3px;
}

.section-title.text-center::after {
    -webkit-animation: section-title-run-center 5s infinite linear;
    animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
    -webkit-animation: section-title-run-sm 5s infinite linear;
    animation: section-title-run-sm 5s infinite linear;
}

@keyframes section-title-run {
    0% {left: 0; } 50% { left : 115px; } 100% { left: 0; }
}

@keyframes section-title-run-center {
    0% { left: 50%; margin-left: -75px; } 50% { left : 50%; margin-left: 45px; } 100% { left: 50%; margin-left: -75px; }
}

@keyframes section-title-run-sm {
    0% {left: 0; } 50% { left : 85px; } 100% { left: 0; }
}